import AdminMenu from 'components/AdminMenu';
import styled from 'styled-components';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {formatDate} from '../middlewares/formatDate';
import {Link, useParams,useNavigate} from 'react-router-dom';
import PagingBox from 'components/PagingBox';


function ContactList({user}) {
    const navigate = useNavigate();
    if(user==null){
        alert('로그인을 해주세요.')
        const redirectURL = `/`;
        navigate(redirectURL);
    }
    useEffect(() => {
            const userData=JSON.parse(user);
            const admin=userData?userData.userAdmin:0;
            if(admin!=1){
                alert('관리자만 접근가능합니다.')
                const redirectURL = `/`; // 리디렉션할 URL 생성
                navigate(redirectURL);
            }
    }, []);
    

    let {pageNum} = useParams();

    pageNum = (pageNum)? pageNum: 1;

    const list_qty = 15;
    const [contactData, setContactData] = useState();
    const [totalPage, setTotalPage] = useState();
    const [totalCount, setTotalCount] = useState(0); //게시물 총 갯수 


    useEffect(() => {
        const fetchContactData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/contact/page`,
                    {
                        page : pageNum ,
                        sumPerPage : list_qty, //목록 갯수 
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                
                if (response.status === 200) {
                    setTotalCount( response.data.totalCount);
                    setTotalPage(response.data.totalPage);

                    const newContactData =  response.data.result[0].map((item, idx )=> ({
                        //기존 데이터에 number 추가 (리스트 넘버링)
                        number : ((response.data.totalCount -( list_qty * (pageNum - 1))) - idx),
                        ...item
                    }))
                    setContactData(newContactData);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };
        fetchContactData(); // Pass the 'id' value to the fetchTotalData function

    }, [pageNum]);



    return (
        <div className="rootConatiner">
            <BannerArti bgImage={`${process.env.PUBLIC_URL}/images/contact_banner_bg.png`}>
                <div className='inner'>
                    <div className='textBox'>
                        <p className="title">CONTACT</p>
                        <p className='content'>
                            친절하고 자세하게 안내드리겠습니다
                        </p>
                    </div>
                </div>
            </BannerArti>

            <ListBox>
                <div className="inner">
                    <div className="basic_list">
                        <AdminMenu boardName="contact" type="list" />

                        <table className="basic_list_table">
                            <thead>
                                <tr className="table_th">
                                    <th className="list_number">
                                        <label htmlFor="allCheck">
                                            {/* <input type="checkbox" id="allCheck"/> */}
                                            번호
                                        </label>
                                    </th>
                                    <th className="list_title">회사</th>
                                    <th className="list_writer">카테고리
                                    </th>
                                    <th className="list_hit">전화번호</th>
                                    <th className="list_date">일정</th>
                                </tr>
                            </thead>

                            {   
                                (contactData) ? 
                                contactData.map((contact) => (
                                    //컬럼 수정시 31번 라인으로 이동
                                    <tbody key={contact.id}>
                                        <tr>
                                            <td className="list_number on">
                                                {/* <input type="checkbox"/> */}
                                                {contact.number}
                                            </td>
                                            <td className="list_title">
                                                <Link to={`/contact/view/${contact.id}`}>
                                                    <span className="titleBox">{contact.contactCompany}</span>
                                                </Link>
                                            </td>
                                            <td className="list_writer more">{contact.contactCategory}</td>
                                            <td className="list_hit more">{contact.contactPhone}</td>
                                            <td className="list_date">{formatDate(contact.contactSchedule)}</td>
                                        </tr>
                                    </tbody>
                                ))
                                : null
                            }
                        </table>
                        <div className="paging_box">
                            <nav aria-label="Page navigation">
                                <PagingBox 
                                    boardName = "contact"
                                    totalPage = {totalPage}
                                />
                            </nav>
                        </div>
                    </div>
                </div>
            </ListBox>

        </div>
    )
}
const ListBox = styled.article `
    padding:50px 0;
    .basic_list_table{
        border-collapse: collapse; table-layout: fixed; width:100%; margin-top:10px;
        tr{
            .list_number{text-align: center; width:10%; }
            .list_title{ width:100%; 
                .reple_box{background:#666; color:#fff; display:inline-block; padding:0px 6px; font-size:12px; border-radius:4px;  }
                a{ 
                    display:flex; 
                    .titleBox{display:inline-block; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;  vertical-align: middle; }
                    .iconWrap{  width:40px; flex-shrink: 0;}
                    .iconWrap2{ width:110px; flex-shrink: 0; padding-left:5px;}

                }
            }
            .list_writer{text-align: center; width:10%; white-space:nowrap; }
            .list_hit{text-align: center; width:10%; }
            .list_date{text-align: center; width:10%;}
            .more{ overflow:hidden; text-overflow:ellipsis; white-space:nowrap;  vertical-align: middle; }

            input[type=checkbox]{margin-right:5px; }
            th{padding:16px 5px; border-top:1px solid #e6e6e6;  }
            td{
                padding:16px 5px; border-top:1px solid #e6e6e6; 
                &.list_number{
                    &.on{color:#40647D; font-size:14px;  }
                }
            }
            &:last-child{
                td{ border-bottom:1px solid #e6e6e6; }
            } 

        }
    }

    @media screen and (max-width: 1250px) { 
        .basic_list_table{
            tr{
                .list_hit{display:none; }
                .list_writer{display:none; }
                .list_title{
                    a{ .iconWrap2{display:none; } }
                }
            }
        }
    }
    @media screen and (max-width: 650px) {
        .basic_list_table{
            font-size: 14px;
            tr{
                .list_date{display:none; }
                td{
                    &.list_number{
                        .on{ font-size:12px; }
                    }
                }
            }
        }
    }
`

const BannerArti = styled.article `
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${ (
    props
) => props.bgImage
    ? `url(${props.bgImage})`
    : `url(${process.env.PUBLIC_URL}/images/example_img.png)`};
    color:#FFF; 

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }

    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
    }
`;

export default ContactList;
