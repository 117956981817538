import { Link } from "react-router-dom";
import "./Home.css"
import axios from 'axios';
import styled from 'styled-components'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay,Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";


//P5
import { useRef, useState, useLayoutEffect } from "react";
import { useEffect } from "react";

import { Helmet } from 'react-helmet';

//Three.js
import * as THREE from 'three';


function Home ({contactModal, setPrivacyPopup, privacyPopup}) {


    const [project, setProject] = useState(); 
    const [column, setColumn] = useState(); 
    const [qnaIndex, setQnaIndex] = useState({}); // 여러 토글 상태를 객체로 관리
    const contentRefs = useRef([]); // 각 토글의 DOM 참조

    const privacyPopupHandler = () => {
        setPrivacyPopup(!privacyPopup);
    }


    useLayoutEffect(() => {
        // 초기 높이를 정확히 계산
        contentRefs.current.forEach((content, index) => {
            if (content) {
                content.style.maxHeight = "0px"; // 닫힌 상태로 시작
            }
        });
    }, []);

    const toggleHandler = (index) => {
        setQnaIndex((prev) => ({
        ...prev,
        [index]: !prev[index], // 상태 반전
        }));

        const content = contentRefs.current[index];
        if (content) {
            if (!qnaIndex[index]) {
                // 열기: 컨텐츠 높이를 정확히 계산
                content.style.maxHeight = `${content.scrollHeight}px`;
            } else {
                // 닫기
                content.style.maxHeight = "0px";
            }
        }
    };

    const mainBannerSlideRef = useRef(null); // Swiper 인스턴스를 참조

    useEffect(() => {
        const getProjectData = async() => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/project/main`);
                if (response.status === 200) {
                    setProject(response.data.result[0]);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                alert(error);
            }
        }
        getProjectData();
    }, [])

    useEffect(() => {
        const getColumnData = async() => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/column/main`);
                if (response.status === 200) {
                    setColumn(response.data.result[0]);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                alert(error);
            }
        }
        getColumnData();
    }, [])

    const agreeRef = useRef(null);
    //input 정의
    const [inputValues, setInputValues] = useState({
        contactName: '',
        contactPhone: '',
        contactContent: '',
    });
    //Submit
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if(agreeRef.current.checked === false){
            alert('개인정보 처리방침에 동의해주세요.');
            return false;
        }
        try {
            const formData = new FormData();
            formData.append('contactName', inputValues.contactName);
            formData.append('contactPhone', inputValues.contactPhone);
            formData.append('contactContent', inputValues.contactContent);
            formData.append('contactCompany', '');
            formData.append('contactCategory', '');
            formData.append('contactEmail', '');
            formData.append('contactUrl', '');
            formData.append('contactRoute', '');
            formData.append('contactPage', '');
            formData.append('contactReference', '');
            formData.append('contactSchedule', '');
            //구글 스크립트
            window.gtag('event', 'conversion', { 'send_to': 'AW-11387827809/MH9OCKqM9O8YEOHskbYq', 'value': 1.0, 'currency': 'KRW' });
            console.log('1');
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/write`, formData);
            console.log('2');
            console.log(response,'response');
            if (response.status === 200) {
                const insertedId = response.data.insertedId; // 삽입된 ID 값
                alert('상담신청이 완료되었습니다.')
                window.location.reload();// 페이지 새로고침
            } else {
                throw new Error('서버 응답 오류');
            }
        } catch (error) {
            console.error(error);
        }
    };

    //input 핸들러
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };

    
    return(
        <>  
            <main className="main">
                <section className="bannerSlide">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        modules={[EffectFade, Navigation, Pagination, Autoplay]}
                        pagination={{
                          clickable: true,
                        }}
                        loop={true}
                        className="mySwiper"
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        effect={"fade"}
                        onSwiper={(swiper) => (mainBannerSlideRef.current = swiper)} // Swiper 인스턴스를 저장
                    >
                        {/* <div className="swiper-wrapper"> */}
                            <SwiperSlide>
                                <article className="slideArti bg1">
                                    <div className="inner">
                                        <div className="halfBox">
                                            <div className="contentBox">
                                                <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/slide1/logo1.png`} alt="팩토리닥터" className="logo" />
                                                <div className="title">공장유지보수 플랫폼<br/>팩토리닥터 신규 제작</div>
                                                <div className="btnWrapDiv">
                                                    <div className="btnList">
                                                        <button
                                                            type="button"
                                                            className="pnBtn prevBtn"
                                                            onClick={() => mainBannerSlideRef.current.slidePrev()} // 이전 슬라이드로 이동
                                                        >
                                                             <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/prevBtn.png`} alt="이전" className="icon" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="pnBtn nextBtn"
                                                            onClick={() => mainBannerSlideRef.current.slideNext()} // 다음 슬라이드로 이동
                                                        >   
                                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/nextBtn.png`} alt="다음" className="icon" />
                                                        </button>
                                                    </div>
                                                    <div className="text">플랫폼 서비스 신규 개발</div>
                                                </div>
                                                <ul className="infoList">
                                                    {/* <li>
                                                        <div className="text">잡오브브라더스 회사소개서</div>
                                                        <a href="#" className="linkBtn">
                                                            다운로드 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/download_icon.png`} alt="다운로드" className="icon" />
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <div className="text">카카오톡 문의하기</div>
                                                        <a href="https://pf.kakao.com/_RCxoxib" target="_blank" className="linkBtn">
                                                            문의하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/link_icon.png`} alt="바로가기" className="icon" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <div className="text">전화문의하기</div>
                                                        <a href="tel:0507-1409-9559" className="linkBtn">
                                                            전화하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/call_icon.png`} alt="전화" className="icon" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="imgBox img1"></div>
                                        </div>
                                    </div>
                                </article>
                            </SwiperSlide>
                            <SwiperSlide>
                                <article className="slideArti bg2">
                                    <div className="inner">
                                        <div className="halfBox">
                                            <div className="contentBox">
                                                <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/slide2/logo.png`} alt="팩토리닥터" className="logo" />
                                                <div className="title">동원홈푸드 가맹 모집<br/>랜딩페이지 신규제작</div>
                                                <div className="btnWrapDiv">
                                                    <div className="btnList">
                                                        <button
                                                            type="button"
                                                            className="pnBtn prevBtn"
                                                            onClick={() => mainBannerSlideRef.current.slidePrev()} // 이전 슬라이드로 이동
                                                        >
                                                             <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/prevBtn.png`} alt="이전" className="icon" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="pnBtn nextBtn"
                                                            onClick={() => mainBannerSlideRef.current.slideNext()} // 다음 슬라이드로 이동
                                                        >   
                                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/nextBtn.png`} alt="다음" className="icon" />
                                                        </button>
                                                    </div>
                                                    <div className="text">랜딩페이지 신규제작</div>
                                                </div>
                                                <ul className="infoList">
                                                    {/* <li>
                                                        <div className="text">잡오브브라더스 회사소개서</div>
                                                        <a href="#" className="linkBtn">
                                                            다운로드 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/download_icon.png`} alt="다운로드" className="icon" />
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <div className="text">카카오톡 문의하기</div>
                                                        <a href="https://pf.kakao.com/_RCxoxib" target="_blank" className="linkBtn">
                                                            문의하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/link_icon.png`} alt="바로가기" className="icon" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <div className="text">전화문의하기</div>
                                                        <a href="tel:0507-1409-9559" className="linkBtn">
                                                            전화하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/call_icon.png`} alt="전화" className="icon" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="imgBox img2"></div>
                                        </div>
                                    </div>
                                </article>
                            </SwiperSlide>
                            <SwiperSlide>
                                <article className="slideArti bg3">
                                    <div className="inner">
                                        <div className="halfBox">
                                            <div className="contentBox">
                                                <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/slide3/logo.png`} alt="테헤란" className="logo" />
                                                <div className="title">법무법인 테헤란<br/>웹서비스 신규 제작</div>
                                                <div className="btnWrapDiv">
                                                    <div className="btnList">
                                                        <button
                                                            type="button"
                                                            className="pnBtn prevBtn"
                                                            onClick={() => mainBannerSlideRef.current.slidePrev()} // 이전 슬라이드로 이동
                                                        >
                                                             <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/prevBtn.png`} alt="이전" className="icon" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="pnBtn nextBtn"
                                                            onClick={() => mainBannerSlideRef.current.slideNext()} // 다음 슬라이드로 이동
                                                        >   
                                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/nextBtn.png`} alt="다음" className="icon" />
                                                        </button>
                                                    </div>
                                                    <div className="text">웹서비스 신규 제작</div>
                                                </div>
                                                <ul className="infoList">
                                                    {/* <li>
                                                        <div className="text">잡오브브라더스 회사소개서</div>
                                                        <a href="#" className="linkBtn">
                                                            다운로드 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/download_icon.png`} alt="다운로드" className="icon" />
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <div className="text">카카오톡 문의하기</div>
                                                        <a href="https://pf.kakao.com/_RCxoxib" target="_blank" className="linkBtn">
                                                            문의하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/link_icon.png`} alt="바로가기" className="icon" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <div className="text">전화문의하기</div>
                                                        <a href="tel:0507-1409-9559" className="linkBtn">
                                                            전화하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/call_icon.png`} alt="전화" className="icon" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="imgBox img3"></div>
                                        </div>
                                    </div>
                                </article>
                            </SwiperSlide>
                            <SwiperSlide>
                                <article className="slideArti bg4">
                                    <div className="inner">
                                        <div className="halfBox">
                                            <div className="contentBox">
                                                <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/slide4/logo.png`} alt="드림성모안과" className="logo" />
                                                <div className="title">드림성모안과<br/>메인 사이트 리뉴얼</div>
                                                <div className="btnWrapDiv">
                                                    <div className="btnList">
                                                        <button
                                                            type="button"
                                                            className="pnBtn prevBtn"
                                                            onClick={() => mainBannerSlideRef.current.slidePrev()} // 이전 슬라이드로 이동
                                                        >
                                                             <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/prevBtn.png`} alt="이전" className="icon" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="pnBtn nextBtn"
                                                            onClick={() => mainBannerSlideRef.current.slideNext()} // 다음 슬라이드로 이동
                                                        >   
                                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/nextBtn.png`} alt="다음" className="icon" />
                                                        </button>
                                                    </div>
                                                    <div className="text">웹서비스 리뉴얼</div>
                                                </div>
                                                <ul className="infoList">
                                                    {/* <li>
                                                        <div className="text">잡오브브라더스 회사소개서</div>
                                                        <a href="#" className="linkBtn">
                                                            다운로드 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/download_icon.png`} alt="다운로드" className="icon" />
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <div className="text">카카오톡 문의하기</div>
                                                        <a href="https://pf.kakao.com/_RCxoxib" target="_blank" className="linkBtn">
                                                            문의하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/link_icon.png`} alt="바로가기" className="icon" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <div className="text">전화문의하기</div>
                                                        <a href="tel:0507-1409-9559" className="linkBtn">
                                                            전화하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/call_icon.png`} alt="전화" className="icon" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="imgBox img4"></div>
                                        </div>
                                    </div>
                                </article>
                            </SwiperSlide>
                            <SwiperSlide>
                                <article className="slideArti bg5">
                                    <div className="inner">
                                        <div className="halfBox">
                                            <div className="contentBox">
                                                <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/slide5/logo.png`} alt="MBN매경골프" className="logo" />
                                                <div className="title">MBN 골프 캠프/투어<br/>통합 웹서비스</div>
                                                <div className="btnWrapDiv">
                                                    <div className="btnList">
                                                        <button
                                                            type="button"
                                                            className="pnBtn prevBtn"
                                                            onClick={() => mainBannerSlideRef.current.slidePrev()} // 이전 슬라이드로 이동
                                                        >
                                                             <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/prevBtn.png`} alt="이전" className="icon" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="pnBtn nextBtn"
                                                            onClick={() => mainBannerSlideRef.current.slideNext()} // 다음 슬라이드로 이동
                                                        >   
                                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/nextBtn.png`} alt="다음" className="icon" />
                                                        </button>
                                                    </div>
                                                    <div className="text">웹서비스 지속 유지보수</div>
                                                </div>
                                                <ul className="infoList">
                                                    {/* <li>
                                                        <div className="text">잡오브브라더스 회사소개서</div>
                                                        <a href="#" className="linkBtn">
                                                            다운로드 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/download_icon.png`} alt="다운로드" className="icon" />
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <div className="text">카카오톡 문의하기</div>
                                                        <a href="https://pf.kakao.com/_RCxoxib" target="_blank" className="linkBtn">
                                                            문의하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/link_icon.png`} alt="바로가기" className="icon" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <div className="text">전화문의하기</div>
                                                        <a href="tel:0507-1409-9559" className="linkBtn">
                                                            전화하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/call_icon.png`} alt="전화" className="icon" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="imgBox img5"></div>
                                        </div>
                                    </div>
                                </article>
                            </SwiperSlide>
                            <SwiperSlide>
                                <article className="slideArti bg6">
                                    <div className="inner">
                                        <div className="halfBox">
                                            <div className="contentBox">
                                                <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/slide6/logo.png`} alt="JH오토솔루션" className="logo" />
                                                <div className="title">JH auto solution<br/>통합 웹서비스 리뉴얼</div>
                                                <div className="btnWrapDiv">
                                                    <div className="btnList">
                                                        <button
                                                            type="button"
                                                            className="pnBtn prevBtn"
                                                            onClick={() => mainBannerSlideRef.current.slidePrev()} // 이전 슬라이드로 이동
                                                        >
                                                             <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/prevBtn.png`} alt="이전" className="icon" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="pnBtn nextBtn"
                                                            onClick={() => mainBannerSlideRef.current.slideNext()} // 다음 슬라이드로 이동
                                                        >   
                                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/nextBtn.png`} alt="다음" className="icon" />
                                                        </button>
                                                    </div>
                                                    <div className="text">웹서비스 리뉴얼 &  지속 유지보수 </div>
                                                </div>
                                                <ul className="infoList">
                                                    {/* <li>
                                                        <div className="text">잡오브브라더스 회사소개서</div>
                                                        <a href="#" className="linkBtn">
                                                            다운로드 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/download_icon.png`} alt="다운로드" className="icon" />
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <div className="text">카카오톡 문의하기</div>
                                                        <a href="https://pf.kakao.com/_RCxoxib" target="_blank" className="linkBtn">
                                                            문의하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/link_icon.png`} alt="바로가기" className="icon" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <div className="text">전화문의하기</div>
                                                        <a href="tel:0507-1409-9559" className="linkBtn">
                                                            전화하기 <img src={`${process.env.PUBLIC_URL}/images/2024/main/bannerSlide/call_icon.png`} alt="전화" className="icon" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="imgBox img6"></div>
                                        </div>
                                    </div>
                                </article>
                            </SwiperSlide>
                        {/* </div> */}
                    </Swiper>
                </section>
                <section className="anySection ani">
                    <div className="inner">
                        <div className="arrowBox">
                            <div className="box">
                                <div className="t1">일반 홈페이지는</div>
                                <div className="t2">누구나 만들 수 있지만</div>
                            </div>
                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/anySection/right_arrow.png`} alt="화살표" className="arrow" />
                            <div className="box active">
                                <div className="t1">기획, 기능 개발, 맞춤형 디자인이 들어간 서비스는 </div>
                                <div className="t2">누구나 만들 수 없습니다</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="countingSection ani">
                    <div className="barBox bar1">
                        <img src={`${process.env.PUBLIC_URL}/images/2024/main/countingSection/bar.png`} alt="JOB OF BORHTERS" className="logo" />
                        <img src={`${process.env.PUBLIC_URL}/images/2024/main/countingSection/bar.png`} alt="JOB OF BORHTERS" className="logo" />
                        <img src={`${process.env.PUBLIC_URL}/images/2024/main/countingSection/bar.png`} alt="JOB OF BORHTERS" className="logo" />
                    </div>
                    <div className="barBox bar2">
                        <img src={`${process.env.PUBLIC_URL}/images/2024/main/countingSection/bar.png`} alt="JOB OF BORHTERS" className="logo" />
                        <img src={`${process.env.PUBLIC_URL}/images/2024/main/countingSection/bar.png`} alt="JOB OF BORHTERS" className="logo" />
                        <img src={`${process.env.PUBLIC_URL}/images/2024/main/countingSection/bar.png`} alt="JOB OF BORHTERS" className="logo" />
                    </div>
                    <div className="inner">
                        <div className="halfBox">
                            <div className="box box1">
                                <img  src={`${process.env.PUBLIC_URL}/images/2024/common/logo.png`} alt="잡오브브라더스" className="logo" />
                                <div className="text">
                                    200개가 넘는 기업에서 제작을 문의하셨고,<br/>
                                    만족스러운 결과를 얻어가셨습니다.
                                </div>
                            </div>
                            <ul className="countingList">
                                <li>
                                    <div className="circle">
                                        <div className="t1">계약 파기 건수</div>
                                        <div className="t2">
                                            {/* <span className="number">0</span> */}
                                            <CountUpOnScroll
                                                start={0}
                                                end={0}
                                                duration={1000}
                                            />
                                            %
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="circle">
                                        <div className="t1">만족도</div>
                                        <div className="t2">
                                            {/* <span className="number">100</span> */}
                                            <CountUpOnScroll
                                                start={0}
                                                end={100}
                                                duration={1000}
                                            />
                                            %
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="circle">
                                        <div className="t1">지인 추천 의향</div>
                                        <div className="t2">
                                            {/* <span className="number">96</span> */}
                                            <CountUpOnScroll
                                                start={0}
                                                end={100}
                                                duration={1000}
                                            />
                                            %
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="whiteBox">
                            대기업, 공기업, 프랜차이즈, 외주업체, 서비스/솔루션 기업, 변호사 등등<br/>
                            <b>
                                각기 다른 업종에서 잡오브브라더스를 통해 웹서비스를 제작하셨고,<br/>
                                <span className="blue">다른 업체에서는 경험할 수 없는 서비스와 결과물를 얻어가시며 만족하셨습니다.</span>
                            </b>
                        </div>
                    </div>
                </section>
                
                <section className="portfolioSection ani">
                    <div className="inner">
                        <div className="main_title_en">Portfolio</div>
                        <div className="main_title">잡오브브라더스 신규 포트폴리오</div>
                        <div className="main_sub_title">잡오브브라더스와 함께 걸어온 소중한 기업들입니다.</div>
                        <ul className="itemList">
                        { (project) &&  
                            project.map((item) => (
                                <li key={item.id}>
                                    <Link to={`./project/view/${item.id}`}>
                                        <div className="item">
                                            <div className="imgBox" style={{ backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/uploads/project/${item.projectFile1}")` }}></div>
                                            <div className="subject">{item.projectName}</div>
                                            <div className="content">{item.projectIntro}</div>
                                        </div>
                                    </Link>
                                </li>
                            ))
                        }
                            
                        </ul>
                        <div className="btnBox">
                            <Link to="/project" className="moreVieBtn">포트폴리오 전체보기</Link>
                        </div>
                    </div>
                </section>
               
                <section className="logoSection ani">
                    <div className="inner">
                        <div className="main_title_en">About JOBOFBROTHERS</div>
                        <div className="main_title">기업 인증, 수상</div>
                        <div className="main_sub_title">
                            잡오브브라더스는 웹개발, 디자인 납품 품질을 높이고 유지하기 위해서<br/>
                            직접 경험하고 인증 받으며, 성장을 위해 최선을 다하고 있습니다.
                        </div>
                        <div className="logoBox">
                            <Swiper
                                spaceBetween={30}
                                loop={true}
                                slidesPerView={4}
                                allowTouchMove={false} 
                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: false,
                                }}
                                loopAdditionalSlides={8}
                                speed={5000}
                                modules={[Autoplay]}
                                onSwiper={(swiper) => {
                                     swiper.update(); // 슬라이더 초기화
                                }}
                            >
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo1.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo2.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo3.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo4.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo5.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo6.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo7.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo8.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo1.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo2.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo3.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo4.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo5.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo6.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo7.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo8.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo1.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo2.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo3.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo4.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo5.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo6.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo7.png`} alt="" className="logo" /></SwiperSlide>
                                <SwiperSlide><img src={`${process.env.PUBLIC_URL}/images/2024/main/logoSection/logo8.png`} alt="" className="logo" /></SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </section>
                <section className="specialSection ani">
                    <div className="inner">
                        <div className="main_title_en">Special Service</div>
                        <div className="main_title">잡오브브라더스 전문가들의 차별성</div>
                        <div className="main_sub_title">
                            웹서비스 제작 노하우와 트렌디한 디자인, 유연한 개발기술로 퀄리티있는 홈페이지를 제작합니다.
                        </div>
                        <div className="slideBox">

                        <Swiper
                            slidesPerView={1.1}
                            spaceBetween={10}
                            className="mySwiper"
                            loop={true}
                            loopAdditionalSlides={10}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                '650': {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                '900': {
                                  slidesPerView: 3,
                                  spaceBetween: 10,
                                },
                            }}
                            modules={[Autoplay]}
                        >
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">지원사업 MVP 기획 및 제작</div>
                                    <div className="contentBox bg1">
                                        중소벤처기업부 창업지원사업 4건 선정 <br/>
                                        정해진 예산, 복잡한 절차 수많은 필요 기능 구현을 <br/>
                                        모든단계를 직접 경험해본 대표와 함께 합니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">Creation Plan</div>
                                    <div className="contentBox bg2">
                                        첫 시작은 정리된 기획요청서부터,<br/>
                                        클라이언트의 관점을 이해하고 성공적인 프로젝트를 <br/>
                                        위해 끊임없이 소통합니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">Trendy Design</div>
                                    <div className="contentBox bg3">
                                        공감을 끌어내는 설계, 시선을 사로잡는 디자인,<br/>
                                        트렌드를 앞서가는 마케팅 전략, <br/>
                                        마케팅 포인트를 잘녹인 기획력 있는 <br/>
                                        디자인은 고객의 공감을 얻고<br/>
                                        매출 / 문의량 상승으로 이어집니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">Develop</div>
                                    <div className="contentBox bg4">
                                        웹어서 보고, 경험한 모든 효과와 기능을 구현합니다.<br/>
                                        전문 개발팀이 Publishing, Fornt-end & Back-end <br/>
                                        개발과 런칭 후 사후 지원까지 책임을 다합니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">Branding & Maketing</div>
                                    <div className="contentBox bg5">
                                        CI/BI, 상세페이지, 리플렛, 패키지까지<br/>
                                        다년간의 마케팅 노하우와<br/>
                                        고퀄리티 디자인 퍼포먼스로 브랜드에 <br/>
                                        숨결을 불어넣습니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* 똑같은거 한번 더 반복한 swiper slide */}
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">지원사업 MVP 기획 및 제작</div>
                                    <div className="contentBox bg1">
                                        중소벤처기업부 창업지원사업 4건 선정 <br/>
                                        정해진 예산, 복잡한 절차 수많은 필요 기능 구현을 <br/>
                                        모든단계를 직접 경험해본 대표와 함께 합니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">Creation Plan</div>
                                    <div className="contentBox bg2">
                                        첫 시작은 정리된 기획요청서부터,<br/>
                                        클라이언트의 관점을 이해하고 성공적인 프로젝트를 <br/>
                                        위해 끊임없이 소통합니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">Trendy Design</div>
                                    <div className="contentBox bg3">
                                        공감을 끌어내는 설계, 시선을 사로잡는 디자인,<br/>
                                        트렌드를 앞서가는 마케팅 전략, <br/>
                                        마케팅 포인트를 잘녹인 기획력 있는 <br/>
                                        디자인은 고객의 공감을 얻고<br/>
                                        매출 / 문의량 상승으로 이어집니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">Develop</div>
                                    <div className="contentBox bg4">
                                        웹어서 보고, 경험한 모든 효과와 기능을 구현합니다.<br/>
                                        전문 개발팀이 Publishing, Fornt-end & Back-end <br/>
                                        개발과 런칭 후 사후 지원까지 책임을 다합니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="itemBox">
                                    <div className="blackTitle">Branding & Maketing</div>
                                    <div className="contentBox bg5">
                                        CI/BI, 상세페이지, 리플렛, 패키지까지<br/>
                                        다년간의 마케팅 노하우와<br/>
                                        고퀄리티 디자인 퍼포먼스로 브랜드에 <br/>
                                        숨결을 불어넣습니다.
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        </div>
                    </div>
                </section> 
                <section className="benefitsSection ani">
                    <div className="inner">
                        <div className="main_title_en">Special Benefits</div>
                        <div className="main_title">웹사이트 제작 시 제공되는 혜택</div>
                        <div className="main_sub_title">
                            지속적인 커뮤니케이션과 함께 필수 웹사이트 기능을 제공하는 것을 우선시하여 <br/>
                            원활한 협업을 통해 성공적인 프로젝트가 마무리되는것을 보장합니다.
                        </div>
                        <div className="slideBox">
                            <Swiper
                                slidesPerView={1.1}
                                spaceBetween={10}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                                className="mySwiper"
                                breakpoints={{
                                    '650': {
                                      slidesPerView: 2.5,
                                      spaceBetween: 10,
                                    },
                                    '900': {
                                        slidesPerView: 2.5,
                                        spaceBetween: 10,
                                      },
                                    '1250': {
                                        slidesPerView: 3.5,
                                        spaceBetween: 10,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img1"></div>
                                        <div className="contentBox">
                                            <div className="title">기획요청서</div>
                                            <div className="content">
                                                웹사이트 제작시 가장 막막한 부분인<br/>
                                                기획을 도와드립니다. 기획요청서를 작성해<br/>
                                                주시면 분석 후 적용시켜드립니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img2"></div>
                                        <div className="contentBox">
                                            <div className="title">반응형 최적화</div>
                                            <div className="content">
                                                모든 프로젝트는 <br/>
                                                PC, Mobile, Tablet PC 디바이스별 <br/>
                                                최적화 반응형 작업으로 진행합니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img3"></div>
                                        <div className="contentBox">
                                            <div className="title">백업파일 제공</div>
                                            <div className="content">
                                                프로젝트를 작업했던 디자인 시안과<br/>
                                                개발 코드의 백업 파일을 <br/>
                                                제공해드립니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img4"></div>
                                        <div className="contentBox">
                                            <div className="title">지도 API 서비스</div>
                                            <div className="content">
                                                위치나 주소를 쉽게 찾을 수 있게 <br/>
                                                이미지가 아닌 구글 지도 API 서비스를<br/>
                                                연동해드립니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img5"></div>
                                        <div className="contentBox">
                                            <div className="title">소셜 로그인</div>
                                            <div className="content">
                                                로그인이 필요한 사이트는<br/>
                                                Naver, Kakao, Google 등 <br/>
                                                소셜로그인 서비스를 지원합니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img6"></div>
                                        <div className="contentBox">
                                            <div className="title">메일, 문자, 카카오톡 보내기</div>
                                            <div className="content">
                                                메일, 문자 알림 연동으로 고객님의 요청을<br/>
                                                놓치지 않고 확인 할 수 있고, 마케팅 메세지를<br/>
                                                간편하게 보낼 수 있게 지원해드립니다.   
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img7"></div>
                                        <div className="contentBox">
                                            <div className="title">유료 이미지 사용</div>
                                            <div className="content">
                                                잡오브브라더스가 사용하는 모든 이미지는 <br/>
                                                저작권 문제가 없는 유료 이미지를 구매하여 <br/>
                                                제공해드립니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img8"></div>
                                        <div className="contentBox">
                                            <div className="title">통계 툴 서비스 제공</div>
                                            <div className="content">
                                                이용자들이 어디에서 사이트를 찾아왔는지<br/>
                                                확인할 수 있는 유입경로 분석 툴 서비스를<br/>
                                                제공해드리고 있습니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img9"></div>
                                        <div className="contentBox">
                                            <div className="title">팝업 관리 서비스 제공</div>
                                            <div className="content">
                                                중요한 정보를 쉽게 확인 할 수 있게<br/>
                                                팝업 관리 서비스를 <br/>
                                                제공해드리고 있습니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img10"></div>
                                        <div className="contentBox">
                                            <div className="title">온라인 상담 신청서비스</div>
                                            <div className="content">
                                                1:1 문의, FAQ, DB 수집 등 <br/>
                                                CS 관리를 쉽고 간편하게 <br/>
                                                사이트내에서 관리가 가능합니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img11"></div>
                                        <div className="contentBox">
                                            <div className="title">검색엔진 최적화(SEO)</div>
                                            <div className="content">
                                                검색사이트에서 키워드 검색이 <br/>
                                                노출이 잘될 수 있게 검색엔진 최적화를<br/>
                                                분석하고 적용시켜드립니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img12"></div>
                                        <div className="contentBox">
                                            <div className="title">사이트 사용설명서</div>
                                            <div className="content">
                                                쉽고 간편하게 어드민 페이지를<br/>
                                                접속하여 기능들을 확인하고 설정할 수 있는<br/>
                                                사이트 사용설명서를 전달해드립니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img13"></div>
                                        <div className="contentBox">
                                            <div className="title">든든한 유지보수 서비스</div>
                                            <div className="content">
                                                잡오브브라더스의 서버 유지 기간동안<br/>
                                                월 2회의 이미지 & 텍스트 무료 수정을<br/>
                                                제공해드리고 있습니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="imgBox img14"></div>
                                        <div className="contentBox">
                                            <div className="title">고도 기능 추가개발</div>
                                            <div className="content">
                                                잡오브브라더스에서 제작된 사이트를<br/>
                                                새로 리뉴얼 하지 않고 고도 기능을<br/>
                                                추가 개발하기 용이하게 개발합니다.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>
                </section>
                <section className="reviewSection ani">
                    <div className="inner">
                        <div className="main_title_en">Review</div>
                        <div className="main_title">믿고 맡길 수 있는 리뷰</div>
                        <div className="main_sub_title">
                            구성원 모두 서로 적극적으로 협업하여 부족한 부분을 개선시켜<br/>
                            최상의 결과물을 만들어냅니다
                        </div>
                        <ul className="reviewList">
                            <li>
                                <div className="item">
                                    <img src={`${process.env.PUBLIC_URL}/images/2024/main/reviewSection/star.png`} alt="별 5개" className="star"/>
                                    <div className="content">
                                        준비해둔 기획안을 잘 파악하고 <br/>
                                        이를 개선할 뿐만 아니라 <br/>
                                        훨씬 좋은 방향으로 제안해주셨습니다.<br/>
                                        덕분에 웹서비스 개발은 의뢰전보다<br/>
                                        높은 퀄리티로 제작되었고,<br/>
                                        <span className="blue">모든 단계를 알기 쉽게 설명 주셔서<br/>
                                        이해가 잘 되게 제작하였습니다.</span> <br className="moBr"/><br className="moBr"/>

                                        확실한 실력으로, <br/>
                                        주변 지인들에게 추천하고 있습니다.
                                    </div>
                                    <div className="grayText">플렛폼 제작 기업</div>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <img src={`${process.env.PUBLIC_URL}/images/2024/main/reviewSection/star.png`} alt="별 5개" className="star"/>
                                    <div className="content">
                                        끝까지 고객과의 약속을 지켜주시는  <br/>
                                        모습에 매우 감사했습니다!  <br/>
                                        개발도 빠르고 피드백도  <br/>
                                        잘 해주십니다 <br className="moBr"/> <br className="moBr"/>

                                        <span className="blue">다음에도 개발을 진행할일이 있으면  <br/>
                                        꼭 다시 일하고 싶습니다.</span>  <br/>
                                        감사합니다~~
                                    </div>
                                    <div className="grayText">프렌차이즈 창업 업체</div>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <img src={`${process.env.PUBLIC_URL}/images/2024/main/reviewSection/star.png`} alt="별 5개" className="star"/>
                                    <div className="content">
                                        수정사항이 많아서 작업 기간이  <br/>
                                        길어졌음에도 불구하고, <span className="blue">친절하게  <br/>
                                        응대해주셔서 어려움 없이 진행할 수 <br/>
                                        있었습니다.</span>  <br className="moBr"/> <br className="moBr"/>

                                        개발자님과 디자이너님  <br/>
                                        모두 고생 많으셨습니다.  <br/>
                                        감사합니다 ^^
                                    </div>
                                    <div className="grayText">개발 솔루션 제작 기업</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="qnaSection ani">
                    <div className="inner">
                        <div className="main_title_en">Q&A</div>
                        <div className="main_title">많이 찾는 질문</div>
                        <ul className="qnaList">
                            <li onClick={() => toggleHandler(1)}>
                                <div className={`item ${qnaIndex[1] ? "active" : ""}`}>
                                    <div className="qnaBox qBox">
                                        <div className="text">처음부터 제작을 진행하려고 하는데, 어떻게 시작해야 할까요?</div>
                                        <button type="button">
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/qnaSection/arrow.png`} alt="화살표" className="arrow" />
                                        </button>
                                    </div>
                                    <div className="showHideBox" ref={(el) => (contentRefs.current[1] = el)}>
                                        <div className="qnaBox aBox">
                                            사이트 제작이 완료되면 추가비용이 일절 발생하지 않습니다.<br/><br/>
                                            <span className="blue">오탈자나 기능오류에 대한 사항은 언제나 무상으로 수정해드리니, 걱정하지 않으셔도 됩니다.</span><br/><br/>
                                            유지보수는 레이아웃 수정, 섹션 추가 등 수정사항이 발생된다면 <br/>
                                            건당으로 비용을 책정하여 수정을 도와드리고 있습니다. (평균 3~5만원 발생)<br/><br/>
                                            <span className="blue">또한 사이트 기능 추가가 용이한 개발 솔루션을 사용하여, 사이트 고도화도 가능합니다.</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li onClick={() => toggleHandler(2)}>
                                <div className={`item ${qnaIndex[2] ? "active" : ""}`}>
                                    <div className="qnaBox qBox">
                                        <div className="text">제작 단계는 어떻게 진행되나요 ?</div>
                                        <button type="button">
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/qnaSection/arrow.png`} alt="화살표" className="arrow" />
                                        </button>
                                    </div>
                                    <div className="showHideBox" ref={(el) => (contentRefs.current[2] = el)}>
                                        <div className="qnaBox aBox">
                                            <span className="blue">1. 제작문의 이후 내용 상담</span><br/>
                                            사업아이템을 분석하고 원하시는 스타일과 디자인, 개발기능 등 상담을 통해 구체화 하고<br/>
                                            유연한 커뮤니케이션으로 클라이언트님의 니즈를 충족시켜 드립니다.<br/><br/>

                                            <span className="blue">2. 견적서 및 계약서 발송</span><br/>
                                            홈페이지 제작에 대한 상담을 토대로<br/>
                                            기본 상품 결제 or 추가 서비스로 상품을 결제합니다.<br/><br/>

                                            <span className="blue">3. 계약 후 채팅방 활성화</span><br/>
                                            홈페이지 제작 중간에 필요한 자료가 생길 수 있고, 홈페이지를 구체화하는 과정에서 클라이언트님의 확인이 필요한 사항이 생깁니다. <br/>
                                            이런 상황을 해결하기 위해 담당 PM이 채팅방으로 안내를 해드립니다.<br/><br/>

                                            <span className="blue">4. 디자인 시안 제작 및 컨펌 완료</span><br/>
                                            디자인 초안을 전달드리며, 확인을 통해 수정사항을 진행합니다.<br/>
                                            정해진 수정횟수를 통해 디자인 작업이 마무리 됩니다.<br/><br/>

                                            <span className="blue">6. 개발작업 진행</span><br/>
                                            완료된 시안을 바탕으로 개발 작업을 진행합니다.<br/>
                                            개발작업은 웹 표준을 기반으로 반응형 웹 사이트로 제작됩니다.<br/><br/>

                                            <span className="blue">7. 최종검수</span> <br/>
                                            디자인, 개발 작업이 끝난 이후, 각 담당 작업자들이 마무리 검수를 진행합니다.<br/>
                                            사이트의 버튼이나, 기능, 오탈자 등을 꼼꼼하게 체크하며 개발 기능이 구현되었는지, 틀어진 부분이 없이 잘 되었는지 확인합니다.<br/>
                                            이후, 고객님께 사이트 url을 제공해 드리고, 고객님과 함께 최종검수를 진행합니다.<br/>
                                            최종 검수시, 사이트의 오류나 오탈자를 수정합니다.<br/>
                                            ( 기본 제작 이외 추가 작업건에 대해서는 별도의 비용이 부과됩니다. )<br/><br/>

                                            <span className="blue">8. 백업파일 제공 및 사이트 이용안내</span><br/>
                                            작업시 사용된 모든 백업파일을 전달드리며,<br/>
                                            메뉴얼 페이지를 통한 사이트 이용 안내를 확인하실 수 있습니다.
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li  onClick={() => toggleHandler(3)}>
                                <div className={`item ${qnaIndex[3] ? "active" : ""}`}>
                                    <div className="qnaBox qBox">
                                        <div className="text">제작 이후 추가로 받는 비용이 있나요 ?  유지보수는 가능한가요 ?</div>
                                        <button type="button">
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/qnaSection/arrow.png`} alt="화살표" className="arrow" />
                                        </button>
                                    </div>
                                    <div className="showHideBox" ref={(el) => (contentRefs.current[3] = el)}>
                                        <div className="qnaBox aBox">
                                            기본 호스팅만 연장해주셔도 무료 도메인, 무제한 텍스트 수정, 월 2회 이미지교체가 제공되어 <br/>
                                            일반적인 회사 홈페이지 구축시 추가비용 없이 대부분 수정이 가능합니다. <br/><br/>
                                            <span className="blue">유지관리 게시판을 운영하여 글 작성시 빠르게 수정해드리고 있습니다.</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li  onClick={() => toggleHandler(4)}>
                                <div className={`item ${qnaIndex[4] ? "active" : ""}`}>
                                    <div className="qnaBox qBox">
                                        <div className="text">웹사이트 관리는 어떻게 하나요 ?</div>
                                        <button type="button">
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/qnaSection/arrow.png`} alt="화살표" className="arrow" />
                                        </button>
                                    </div>
                                    <div className="showHideBox" ref={(el) => (contentRefs.current[4] = el)}>
                                        <div className="qnaBox aBox">
                                            개발이후 관리자페이지를 이용하여 컨텐츠 업로드, 회원관리, 팝업설정, 접속자로그등을 확인하실 수 있습니다. <br/>
                                            또한 제작이 완료된다면 서버 및 원본파일, 사용안내서를 제공해드리고 있습니다.  <br/> <br/>
                                            <span className="blue">잡오브브라더스에서는 전문 인력이 없는 대표님들을 위해 유지보수, 리뉴얼 상품 (구독형)도 구성되어 있으니, 많은 관심 부탁드립니다.</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li  onClick={() => toggleHandler(5)}>
                                <div className={`item ${qnaIndex[5] ? "active" : ""}`}>
                                    <div className="qnaBox qBox">
                                        <div className="text">저작권 문제는 걱정 안해도 될까요 ?</div>
                                        <button type="button">
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/main/qnaSection/arrow.png`} alt="화살표" className="arrow" />
                                        </button>
                                    </div>
                                    <div className="showHideBox" ref={(el) => (contentRefs.current[5] = el)}>
                                        <div className="qnaBox aBox">
                                            잡오브브라더스에서 사용되는 모든 폰트, 이미지, 코드 등 소스를 유료로 구매한 후 저작권을 준수하여 사용하고 있어서  <br/>
                                            <span className="blue">작업한 최종 결과물에 한해 자유롭게 상업적인 용도로 사용하실 수 있습니다.</span>  <br/> <br/>

                                            완성된 디자인의 2차변형 및 판매는 금지되어 있으며, 해당 문제로 인한 피해 발생시,  <br/>
                                            잡오브브라더스에서는 책임이 없음을 안내드립니다.
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="clientSection ani">
                    <div className="inner">
                        <div className="main_title_en">Client</div>
                        <div className="main_title">잡오브브라더스와 함께한 기업</div>
                        <div className="main_sub_title">잡오브브라더스와 함께 걸어온 소중한 고객님들입니다.</div>
                        <div className="logoWrap">
                            <ul className="logoList">
                                <li>
                                    <div className="item logo1"></div>
                                </li>
                                <li>
                                    <div className="item logo2"></div>
                                </li>
                                <li>
                                    <div className="item logo3"></div>
                                </li>
                                <li>
                                    <div className="item logo4"></div>
                                </li>
                                <li>
                                    <div className="item logo5"></div>
                                </li>
                                <li>
                                    <div className="item logo6"></div>
                                </li>
                                <li>
                                    <div className="item logo7"></div>
                                </li>
                                <li>
                                    <div className="item logo8"></div>
                                </li>
                                <li>
                                    <div className="item logo9"></div>
                                </li>
                                <li>
                                    <div className="item logo10"></div>
                                </li>
                                <li>
                                    <div className="item logo11"></div>
                                </li>
                                <li>
                                    <div className="item logo12"></div>
                                </li>
                                <li>
                                    <div className="item logo13"></div>
                                </li>
                                <li>
                                    <div className="item logo14"></div>
                                </li>
                                <li>
                                    <div className="item logo15"></div>
                                </li>
                                <li>
                                    <div className="item logo16"></div>
                                </li>
                                <li>
                                    <div className="item logo17"></div>
                                </li>
                                <li>
                                    <div className="item logo18"></div>
                                </li>
                                <li>
                                    <div className="item logo19"></div>
                                </li>
                                <li>
                                    <div className="item logo20"></div>
                                </li>
                                <li>
                                    <div className="item logo21"></div>
                                </li>
                                <li>
                                    <div className="item logo22"></div>
                                </li>
                                <li>
                                    <div className="item logo23"></div>
                                </li>
                                <li>
                                    <div className="item logo24"></div>
                                </li>
                                <li>
                                    <div className="item logo25"></div>
                                </li>
                                <li>
                                    <div className="item logo26"></div>
                                </li>
                                <li>
                                    <div className="item logo27"></div>
                                </li>
                                <li>
                                    <div className="item logo28"></div>
                                </li>
                                <li>
                                    <div className="item logo29"></div>
                                </li>
                                <li>
                                    <div className="item logo30"></div>
                                </li>
                                <li>
                                    <div className="item logo31"></div>
                                </li>
                                <li>
                                    <div className="item logo32"></div>
                                </li>
                                <li>
                                    <div className="item logo33"></div>
                                </li>
                                <li>
                                    <div className="item logo34"></div>
                                </li>
                                <li>
                                    <div className="item logo35"></div>
                                </li>
                                <li>
                                    <div className="item logo36"></div>
                                </li>
                                <li>
                                    <div className="item logo37"></div>
                                </li>
                                <li>
                                    <div className="item logo38"></div>
                                </li>
                                <li>
                                    <div className="item logo39"></div>
                                </li>
                                <li>
                                    <div className="item logo40"></div>
                                </li>
                                <li>
                                    <div className="item logo41"></div>
                                </li>
                                <li>
                                    <div className="item logo42"></div>
                                </li>
                                <li>
                                    <div className="item logo43"></div>
                                </li>
                                <li>
                                    <div className="item logo44"></div>
                                </li>
                                <li>
                                    <div className="item logo45"></div>
                                </li>
                                <li>
                                    <div className="item logo46"></div>
                                </li>
                                <li>
                                    <div className="item logo47"></div>
                                </li>
                                <li>
                                    <div className="item logo48"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                
                <section className="applySection ani">
                    <div className="inner">
                        <div className="halfBox">
                            <div className="leftBox">
                                <img src={`${process.env.PUBLIC_URL}/images/2024/main/applySection/white_logo.png`} alt="JOB OF BROTHERS" className="logo" />
                                <div className="title">'진짜'들의 <span className="blue">약속 5가지</span></div>
                            
                                <ul className="pointList">
                                    <li>
                                        <div className="point">Promise 01</div>
                                        <div className="item">
                                            공장에서 찍어낸 듯한 템플릿이나 웹 빌더는 이제 그만! <br/>
                                            <b className="blue">저희는 귀사만을 위한 맞춤형 서비스를 제공합니다.</b>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="point">Promise 02</div>
                                        <div className="item">
                                            ‘진짜 전문가’의 시선으로 <br/>
                                            <b className="blue">더 나은 방향을 제시해드리겠습니다.</b>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="point">Promise 03</div>
                                        <div className="item">
                                            맡은 바 책임을 다하며, 합리적인 비용으로 <br/>
                                            <b className="blue">최고의 가치를 제안해드립니다.</b>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="point">Promise 04</div>
                                        <div className="item">
                                            우리는 귀사가 만족할 수 밖에 없는 결과물을 창출합니다.  <br/>
                                            <b className="blue">함께하는 과정에서 더 큰 만족을 느끼실 것입니다.</b>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="point">Promise 05</div>
                                        <div className="item">
                                            저희는 능력 밖의 일이라면 솔직하게 말씀드립니다.   <br/>
                                            <b className="blue">이를 통해 불필요한 시간과 비용을 아끼실 수 있습니다.</b><br/>
                                            <span className="small">IT 업계에서는 종종 능력 밖의 프로젝트를 수주하여 책임을 다하지 못하는 경우가 있습니다.</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="rightBox">
                                <form onSubmit={onSubmitHandler}  autoComplete="off">
                                <img src={`${process.env.PUBLIC_URL}/images/2024/main/applySection/black_logo.png`} alt="JOB OF BROTHERS" className="logo" />
                                <div className="title">웹서비스 간편 제작문의</div>
                                <ul className="applyFormList">
                                    <li>
                                        <div className="label">이름 <span className="red">*</span></div>
                                        <div className="iptBox">
        
        
        
                                            <input type="text" name="contactName" required onChange={handleInputChange} className="ipt" placeholder="성함을 입력해주세요." />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="label">연락처 <span className="red">*</span></div>
                                        <div className="iptBox">
                                            <input type="text" name="contactPhone" required onChange={handleInputChange}  className="ipt" placeholder="연락처를 입력해주세요." />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="label">의뢰내용 <span className="red">*</span></div>
                                        <div className="iptBox">
                                            <textarea className="ipt" name="contactContent" required onChange={handleInputChange}  placeholder="내용을 입력해주세요."></textarea>
                                        </div>
                                    </li>
                                </ul>
                                <div className="agreeCheckBox">
                                    <label>
                                    <input type="checkbox" id="agree" ref={agreeRef}/>

                                        <div className="textBox">
                                            개인정보 수집 동의 <span className="black" onClick={privacyPopupHandler}>(확인)</span>
                                        </div>
                                    </label>
                                </div>
                                <button className="submitBtn" type="submit">문의하기</button>
                                <div className="telBox">
                                    <img src={`${process.env.PUBLIC_URL}/images/2024/main/applySection/call_btn.png`} alt="전화기" className="icon" />
                                    <div className="text">0507-1409-9559</div>
                                </div>
                                <div className="address">
                                    서울특별시 금천구 가산디지털2로 173, 11층 1117호
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>


            <PrivacyPopup active={privacyPopup} onClick={privacyPopupHandler}>
                <div className="popupContainer">
                    <div className="btnWrap">
                        <div className="btn">
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="contentBox" onClick={(e) => {e.stopPropagation();}}>
                        <div className="title">개인정보 처리방침</div>
                        <div className="content">
                            JOB OF BROTHERS(이하 "회사"라 합니다)는 『개인정보 보호법』,<br/>
                            『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등 준수하여야 할 관련 법규상의 개인정보 보호규정을 준수하며,<br/>
                            관련 법규에 의거한 개인정보 처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.<br/>
                            이 개인정보 처리방침의 "회원"이란 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』제2조 제4호의 정보통신 서비스 제공자가 제공하는 정보통신 서비스 이용자에 한정합니다.<br/>
                            회사는 회원의 개인정보를 수집 이용하고자 하는 경우에는 『개인정보 보호법』 제15조, 제22조 및 제24조에 따라 회원님 본인의 동의를 얻어야 합니다.<br/>
                            또한, 회원으로부터 취득한 개인정보는 『개인정보보호법』제17조 및 제22조, 제24조에 따라 제3자에게 제공할 경우 회원님 본인의 사전 동의를 얻어야 합니다.<br/>
                            이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 이전부터 공지사항을 통하여 고지할 것입니다.<br/>
                            <br/>
                            1. 개인정보 수집 이용 목적<br/>
                            1) 홈페이지 회원가입 및 관리<br/>
                            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 인증, 회원자격 유지 관리, 제한적 본인 확인제 시행에 따른 본인 확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등<br/>
                            2) 민원업무 처리<br/>
                            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등<br/>
                            3) 재화 또는 서비스 제공 서비스 개발 및 개선, 컨텐츠 제공, 청구서 발송, 금융거래 본인인증 및 금융서비스, 연령인증, 요금결제·정산, 연체자 관리 및 채무 불이행시 위험관리, 채권추심, 대출/투자 서비스 제공 등<br/>
                            4) 마케팅 및 광고에의 활용<br/>
                            신규 서비스 개발과 이벤트 행사에 따른 최신정보 전달 및 맞춤 서비스 제공, 통계학적 분석에 따른 서비스 제공 및 광고 게재 등<br/>
                            <br/>
                            2. 수집 이용할 개인정보의 내용<br/>
                            회사는 회원 가입, 원활한 고객 상담, 각종 서비스 등 기본적인 서비스 제공을 위한 필수 정보와 고객 맞춤 서비스 제공을 위하여 아래와 같이 개인정보를 수집하고 있습니다.<br/>
                            1) 개인 식별 정보<br/>
                            성명, 생년월일, 성별, 사업자번호, 주민등록증발급일, 연락처(휴대전화, 자택, 직장), 주소(자택, 직장), 이메일, 직장명, 부서, 직위, 국적, 운전면허번호, 여권번호, 외국인등록번호, ID, 비밀번호, 접속로그, 쿠키, 서비스 이용 기록, 접속 IP 정보, 음성 DATA 등<br/>
                            2) 투자 서비스 이용 시<br/>
                            은행계좌정보, 주민(법인)등록번호(투자수익에 대한 원천징수신고서용)<br/>
                            <br/>
                            3. 개인정보의 보유 및 이용 기간<br/>
                            회사는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보를 회원 탈퇴후 또는 채권 채무관계 종료일로부터 5년(단, 관련 법령의 별도의 규정이 명시되어 있는 경우 그 기간을 따름)동안 보유합니다.<br/>
                            <br/>
                            4. 개인정보의 파기 절차 및 방법<br/>
                            회사는 원칙적으로 개인정보 수집 및 이용 목적이 달성되거나 보유 및 이용 기간이 종료한 경우 해당정보를 지체 없이 파기합니다. 파기 절차, 기한 및 방법은 다음과 같습니다.<br/>
                            <br/>
                            1) 파기 절차 : 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 데이터베이스 시스템(DB)으로 옮겨져 (종이의 경우 별도의 서류함) 내부방침 및 기타 관련법령에 의한 정보보호 사유에 따라 (보유 및 이용 기간 참조) 일정기간 저장된 후 파기됩니다.<br/>
                            2) 파기 기한 : 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br/>
                            3) 파기 방법 : 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
                            <br/>
                            5. 정보주체의 권리, 의무 및 그 행사방법<br/>
                            1) 개인정보의 열람 정정<br/>
                            회원 및 법정대리인은 언제든지 등록되어 있는 고객의 개인정보를 열람하거나 정정할 수 있습니다.<br/>
                            개인정보 열람 및 정정은 "회원정보 수정"에서 직접 처리할 수 있으며, 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, FAX 등을 통해서도 가능합니다.<br/>
                            2) 개인정보의 삭제 요구<br/>
                            정보 제공 주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br/>
                            3) 개인정보의 수집·이용·제공 동의철회<br/>
                            회원은 언제든지 개인정보의 수집 이용 제공에 대한 동의내용을 철회할 수 있습니다. 동의 철회는 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, FAX 등을 통해서 신청할 수 있으며, 회사는 접수 즉시 동의 철회 등록, 개인정보 파기 등의 조치를 취한 후 해당사실을 고객에게 통지합니다.<br/>
                            다만, 회사는 이용자 혹은 법정 대리인의 요청에 의해 동의철회, 파기된 개인정보는 "개인정보 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/>
                            <br/>
                            6. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항<br/>
                            회사는 고객의 정보를 수시로 저장하고 불러오는 "쿠키(cookie)"를 운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에 보내는 아주 작은 텍스트 파일이며 이용자의 컴퓨터 하드디스크에 저장되기도 합니다.<br/>
                            1) 쿠키 등 사용 목적<br/>
                            회원과 비회원의 접속빈도나 방문시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공 등을 위하여 사용될 수 있습니다.<br/>
                            2) 쿠키의 설치 운영 및 거부<br/>
                            회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 회원이 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다. ☞ 설정 방법 : 웹 브라우저 상단의 도구 → 인터넷 옵션 → 개인정보<br/>
                            <br/>
                            7. 개인정보에 관한 민원서비스<br/>
                            회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
                            1) 개인정보보호책임자<br/>
                            - 기업명 : job of brothers<br/>
                            - 직 책 : 대표<br/>
                            - 전화번호 : 010 - 5594 - 9559<br/>
                            - e-mail : dhrn0623@naver.com<br/>
                            2) 민원 처리<br/>
                            회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있으며, 회사는 고객의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br/>
                            3) 기타 신고 또는 상담센터<br/>
                            - 개인정보분쟁조정위원회 (www.1336.or.kr ☏ 1336)<br/>
                            - 정보보호마크인증위원회 (www.eprivacy.or.kr ☏ 02-580-0533~4)<br/>
                            - 대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr ☏ 02-3480-3600)<br/>
                            - 경찰청 사이버안전국 (www.ctrc.go.kr ☏ 02-392-0330)<br/>
                            <br/>
                            8. 개인정보의 안전성 확보 조치<br/>
                            회사는 『개인정보보호법』제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적 관리적 및 물리적 조치를 하고 있습니다.<br/>
                            1) 개인정보 취급 직원의 최소화 및 교육<br/>
                            개인정보를 취급하는 직원을 지정하고 업무를 담당자에 한정시키고 관리 직원을 최소화하여 개인정보를 관리를 시행하고 있습니다.<br/>
                            2) 내부관리계획의 수립 및 시행<br/>
                            개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br/>
                            3) 특명 감사 실시 개인정보 취급 관련 안정성 확보를 위해 정기적(월 1회)으로 자체 특명 감사를 실시하고 있습니다.<br/>
                            4) 개인정보의 암호화<br/>
                            이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br/>
                            5) 해킹 등에 대비한 기술적 대책<br/>
                            회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적 물리적으로 감시 및 차단하고 있습니다.<br/>
                            6) 개인정보에 대한 접근 제한<br/>
                            개인정보를 처리하는 데이터베이스 시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며, 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/>
                            7) 접속기록의 보관 및 위변조 방지<br/>
                            개인정보처리 시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위 변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br/>
                            8) 문서보안을 위한 잠금장치 사용<br/>
                            개인정보가 포함된 서류, 보조저장매체 등은 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br/>
                            9) 비인가자에 대한 출입 통제<br/>
                            개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립 운영하고 있습니다.<br/>
                        </div>
                    </div>
                </div>
            </PrivacyPopup>
        </>
    );
}

// 카운팅 컴포넌트
const CountUpOnScroll = ({ start, end, duration }) => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // 한번 관찰되면 멈춤
          }
        },
        { threshold: 0.5 } // 요소의 50%가 보일 때 트리거
      );
      if (elementRef.current) {
        observer.observe(elementRef.current);
      }
      return () => observer.disconnect();
    }, []);
  
    const count = useCountUp(start, end, duration, isVisible);
  
    return (
      <span ref={elementRef} className="number">
        {count}
      </span>
    );
};

// 카운팅 숫자 애니메이션을 위한 커스텀 훅
const useCountUp = (start, end, duration, trigger) => {
    const [count, setCount] = useState(start);
  
    useEffect(() => {
      if (!trigger) return;
  
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        setCount(Math.floor(progress * (end - start) + start));
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }, [trigger, start, end, duration]);
  
    return count;
};


const PrivacyPopup = styled.div`
    position: fixed; z-index:999; background-color: rgba(0,0,0,0.5);
    left:0; top:0; width:100%; height:100%; padding: 10px;
    display:flex; justify-content: center; align-items: center;
    opacity:${(props)=> props.active ? "1": "0"}; 
    visibility: ${(props) => props.active ? 'visible':'hidden'}; 
    transition:opacity 0.5s, visibility 0.5s;
    
    .popupContainer{ 
        max-width:600px; 
        .btnWrap{
            display:flex; justify-content: flex-end; align-items: center;
            .btn{ 
                width: 40px; height:40px; 
                cursor: pointer;
                >span{ 
                    display:block; width:100%; height:4px; background-color:#fff; margin-top:10px; 
                    &:nth-child(1){ transform: translateY(7px) rotate(45deg); }
                    &:nth-child(2){ transform: translateY(-7px) rotate(-45deg); }
                }
            }
        }
        .contentBox{ 
            background-color:#fff; padding:40px 20px; max-height:80vh; overflow-y:scroll;  
            .title{ text-align:center; font-size:30px; font-weight:600;  }
            .content{ margin-top:40px; }
            @media screen and (max-width: 650px) {
                .title{ font-size:20px;  }
                .content{ font-size:14px; }
            }
        }
        
    }

`;

export default Home; 


///원래 메인
{/* <Helmet>
<title>잡오브브라더스 : JOB OF BROTHERS</title>
    <meta name="description" content="반응형 홈페이지, 웹서비스, 지원사업 MVP 책임 제작, 청창사 수행 기업, 믿음에 책임감으로 대답해드립니다." />
</Helmet>
<section className="main">
    <article className="BannerArti ani">
        <div className="inner">
            <div className="contentBox">
                <h1>
                    <span data-text="Job Of Brothers">Job Of Brothers </span>
                    <span data-text="Digital Agency">Digital Agency </span>
                    <span data-text="For Your Brand">For Your Brand</span>
                </h1>
                <div className="hashTagBox">
                    <ul className="hashtagList">
                        <li><div className="tag yellow">#웹개발</div></li>
                        <li><div className="tag mint">#디자인</div></li>
                        <li><div className="tag blue">#브랜딩&마케팅</div></li>
                    </ul>
                </div>
            </div>
            <div className="logoBox">
                <div className="jobWrap">
                    <div className="circle item"></div>
                    <div className="J item"></div>
                    <div className="B B1 item"></div>
                    <div className="B B2 item"></div> 
                </div>
            </div>
        </div>
    </article>            

    <article className="serviceArti ani">
        <div className="inner">
            <h2><span>Service</span></h2>
            <Swiper 
                className="mySwiper serviceSlide"
                effect={"fade"}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[EffectFade , Autoplay]}
                onSlideChange={handleSlideChange}
                loop={true}
            >
                <SwiperSlide>
                    <ul className="item">
                        <li className="contentBox">
                            <h3><span>Create</span></h3>
                            <ul className="content">
                                <li>
                                    <div className="slideNumber">
                                        <span className="nb orange">01</span>
                                        <span className="line"><b></b></span>
                                        <span className="nb">05</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="text">
                                        모든 기획을 클라이언트와 함께. <br/>
                                        클라이언트의 관점을 이해하고자 <br/>
                                        끊임없이 소통합니다.
                                    </div>
                                </li>
                            </ul>
                            <div className="number">
                                01
                            </div>
                        </li>
                        <li className="imgBox">
                            {(activeSlide === 0) && <CreateThree />}
                        </li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide>
                    <ul className="item">
                        <li className="contentBox">
                            <h3><span>Design</span></h3>
                            <ul className="content">
                                <li>
                                    <div className="slideNumber">
                                        <span className="nb orange">02</span>
                                        <span className="line"><b></b></span>
                                        <span className="nb">05</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="text">
                                        PC, Tablet, Mobile 모든 디바이스에서 <br/>
                                        메시지가 전달되어야 하니까. <br/>
                                        반응형으로 디자인하여 디바이스가 <br/>
                                        달라도 일관된 경험을 제공합니다.
                                    </div>
                                </li>
                            </ul>
                            <div className="number">
                                02
                            </div>
                        </li>
                        <li className="imgBox">
                            {(activeSlide === 1) && <DesginThree />}
                        </li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide>
                    <ul className="item">
                        <li className="contentBox">
                            <h3><span>Develop</span></h3>
                            <ul className="content">
                                <li>
                                    <div className="slideNumber">
                                        <span className="nb orange">03</span>
                                        <span className="line"><b></b></span>
                                        <span className="nb">05</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="text">
                                        웹에서 보고, 경험한 모든 효과와 <br/> 
                                        기능을 구현합니다. <br/> 
                                        전문 개발팀이 Publishing, <br/> 
                                        Front-end & Back-end 개발과 테스트 <br/> 
                                        런칭 후 사후지원까지 책임을 다합니다.
                                    </div>
                                </li>
                            </ul>
                            <div className="number">
                                03
                            </div>
                        </li>
                        <li className="imgBox">
                            {(activeSlide === 2) && <DevelopThree /> }
                        </li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide>
                    <ul className="item">
                        <li className="contentBox">
                            <h3><span>Branding& <br/>Maketing</span></h3>
                            <ul className="content">
                                <li>
                                    <div className="slideNumber">
                                        <span className="nb orange">04</span>
                                        <span className="line"><b></b></span>
                                        <span className="nb">05</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="text">
                                    CI/BI, 상세페이지, 리플렛, 패키지부터 <br/>
                                    제품 촬영까지 다년간의 마케팅 노하우와  <br/>
                                    고퀄리티 디자인 퍼포먼스로 <br/>
                                    브랜드에 숨결을 불어넣습니다.
                                    </div>
                                </li>
                            </ul>
                            <div className="number">
                                04
                            </div>
                        </li>
                        <li className="imgBox">
                            {(activeSlide === 3) && <BrandingThree />}
                            
                        </li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide>
                    <ul className="item">
                        <li className="contentBox">
                            <h3><span>지원사업 MVP <br/>기획 및 제작</span></h3>
                            <ul className="content">
                                <li>
                                    <div className="slideNumber">
                                        <span className="nb orange">05</span>
                                        <span className="line"><b></b></span>
                                        <span className="nb">05</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="text">
                                        중소벤처기업부 창업지원사업 (창업 패키지) <br/>4건 선정 
                                        4건 선정 정해진 예산, 복잡한 절차,  <br/>
                                        수많은 필요 기능 구현을 대표님과 함께합니다.
                                    </div>
                                </li>
                            </ul>
                            <div className="number">
                                05
                            </div>
                        </li>
                        <li className="imgBox">
                            {(activeSlide === 4) && <MVPThree />}
                        </li>
                    </ul>
                </SwiperSlide>
            </Swiper>
        </div>
    </article>

    <article className="projectArti ani">
        <div className="inner">
            <h2><span>Project</span></h2>
        </div>
        <ul className="list">
                { (project) &&  
                project.map((item) => (
                    <li  key={item.id}>
                        <Link to={`./project/view/${item.id}`}>
                            <div className="item">
                                <div className="imgBox" style={{ backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/uploads/project/${item.projectFile1}")` }}></div>
                                <div className="hoverBox">
                                    <div className="content">
                                        <h3>
                                            {item.projectName}
                                        </h3>
                                        <img src={`${process.env.PUBLIC_URL}/images/main_portfolio_arti.svg`} alt="arrow" className="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))
            }
            
        </ul>
        <div className="moreBtnWrap">
            <Link to="/project">
                <div className="moreBtn">
                    <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow" > 
                        <path d="M16.25 19.7322V0L9.75 0V19.7322H0L13 33L26 19.7322H16.25Z" fill=""/>
                    </svg>
                    <span className="txt">LOAD MORE</span>
                </div>
            </Link>
        </div>
    </article>

    <article className="columnArti ani">
        <div className="inner">
            <h2 className="right"><span>Column</span></h2>

            <div className="listWrap">
                <div className="AllBoreBtn">
                    <Link to="/column">전체보기</Link>
                </div>
                <ul className="listBox">
                    { (column) &&  
                        column.map((item) => (
                            <li className="ani" key={item.id}>
                                <Link to={`./column/view/${item.id}`}>
                                    <div className="item">
                                        <div className="imgBox" style={{ backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/uploads/column/${item.columnFile1}")` }}></div>
                                        <div className="category">{item.columnCategory}</div>
                                        <div className="title">{item.columnTitle}</div>
                                        <div className="moreBtn">
                                        <span>Read more</span> 
                                        <svg width="53" height="26" viewBox="0 0 53 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow">
                                            <path d="M39.7831 9.75H0V16.25H39.7831V26L53 13L39.7831 0V9.75Z" fill=""/>
                                        </svg>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </article>  

    <article className="partnerArti ani">
        <div className="inner">
            <h2><span>A partner company</span></h2>
        </div>
        <div className="logoWrap">
            <div className="marquee_group">
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo01.png`} alt="숙명여자대학교"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo02.png`} alt="퀸잇"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo03.png`} alt="하루엔소쿠"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo04.png`} alt="월미당"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo05.png`} alt="자블 Jabble."/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo06.png`} alt="공인나눔번역센터"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo07.png`} alt="세이브더칠드런"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo08.png`} alt="한국코치협회"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo09.png`} alt="MBN"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo10.png`} alt="Gnetworks"/></div>
            </div>
            <div className="marquee_group">
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo01.png`} alt="숙명여자대학교"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo02.png`} alt="퀸잇"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo03.png`} alt="하루엔소쿠"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo04.png`} alt="월미당"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo05.png`} alt="자블 Jabble."/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo06.png`} alt="공인나눔번역센터"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo07.png`} alt="세이브더칠드런"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo08.png`} alt="한국코치협회"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo09.png`} alt="MBN"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo10.png`} alt="Gnetworks"/></div>
            </div>
        </div>
        <div className="logoWrap marquee--reverse">
            <div className="marquee_group">
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo11.png`} alt="재단법인 여민재단"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo12.png`} alt="홍삼만"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo13.png`} alt="애니파킹"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo14.png`} alt="성결대학교"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo15.png`} alt="문화프레임빅테이터연구소"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo16.png`} alt="미꿈사"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo17.png`} alt="유니타"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo18.png`} alt="중앙대학교"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo19.png`} alt="오프닝즈"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo20.png`} alt="법무법인 온조"/></div>
            </div>
            <div className="marquee_group">
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo11.png`} alt="재단법인 여민재단"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo12.png`} alt="홍삼만"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo13.png`} alt="애니파킹"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo14.png`} alt="성결대학교"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo15.png`} alt="문화프레임빅테이터연구소"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo16.png`} alt="미꿈사"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo17.png`} alt="유니타"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo18.png`} alt="중앙대학교"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo19.png`} alt="오프닝즈"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo20.png`} alt="법무법인 온조"/></div>
            </div>
        </div>
        <div className="logoWrap">
            <div className="marquee_group">
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo21.png`} alt="APGREEN"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo22.png`} alt="ECOPACK-IT"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo23.png`} alt="Outdo"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo24.png`} alt="MINERVA"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo25.png`} alt="DR.STRENGTH"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo26.png`} alt="서울신용보증재단"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo27.png`} alt="매일경제"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo28.png`} alt="서울특별시"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo29.png`} alt="SUPERBEBN"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo30.png`} alt="법무법인 한미"/></div>
            </div>
            <div className="marquee_group">
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo21.png`} alt="APGREEN"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo22.png`} alt="ECOPACK-IT"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo23.png`} alt="Outdo"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo24.png`} alt="MINERVA"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo25.png`} alt="DR.STRENGTH"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo26.png`} alt="서울신용보증재단"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo27.png`} alt="매일경제"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo28.png`} alt="서울특별시"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo29.png`} alt="SUPERBEBN"/></div>
                <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo30.png`} alt="법무법인 한미"/></div>
            </div>
        </div>
        
    </article>

    <article className="contactArti ani">
        <div className="inner">
            <div className="pillar">
                <ContactTree />
            </div>
            <div className="contentBox">
                <h3>
                    Create Your Service<br/>
                    with <span>BROTHERS</span>
                </h3>

                <div className="btnWrap">
                    <div className="btn" onClick={contactModalHandler} >
                        <span>Contact</span>
                    </div>
                </div>
            </div>
            
        </div>
    </article>
    
</section> */}
